body {
  background-color: #f39237;
  padding: 2vmin;
}
.workArea {
  width: 80vw;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  padding: 0 1.5em 1.5em;
  border-radius: 5px;
}
.App {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectBox {
  border: 1px black solid;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6em !important;
  cursor: pointer;
}
.selectDemo {
  font-size: 20px;
  margin-bottom: 0.5em;
  white-space: nowrap;
}
.selectName {
  text-align: center;
  word-wrap: break-word;
}
.stuck {
  position: sticky !important;
  top: 20px;
}
.displayletterbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* height: 23vmin; */
  /* max-height: 40em; */
}
.displayTitle {
  text-align: center;
  word-wrap: break-word;
  font-weight: bold;
  font-size: 1.2em;
}
.displayLetter {
  font-size: 8vmin;
  line-height: 8vmin;
  margin: 1vmin 0;
  white-space: nowrap;
}
.comboDemo {
  margin-top: 0.3em;
  font-size: 3em;
}
.textDisplay {
  min-height: 2em;
  word-wrap: break-word;
  margin-top: -10px;
  cursor: pointer;
}

#selected {
  color: #f39237;
}
.titleWord {
  margin-right: 1em;
  height: 8vmin;
}
.gitRequest {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: 2em;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vmin;
  font-weight: bold;
  height: 100%;
  line-height: 3vmin;
  margin-bottom: 2vmin;
}
.ui.attached.segment {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.animateText {
  animation: fadeIn;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
.half {
  width: 50%;
}
.ui.stretched.grid>.column>*{
  flex-grow:0;
}
@media (max-width: 1130px) {
  .center {
    flex-direction: column;
    margin: 1em 0;
  }

  .wrapped {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .workArea {
    padding: 0 0.7em 0.7em;
  }
  .stuck{
    width: 100% !important;
  }
}
@media (max-width: 768px) {
    .ui.stackable.grid {
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
  }
  .ui.grid>.stackable.stackable.row>.column, .ui.stackable.grid>.column.grid>.column, .ui.stackable.grid>.column.row>.column, .ui.stackable.grid>.column:not(.row), .ui.stackable.grid>.row>.column, .ui.stackable.grid>.row>.wide.column, .ui.stackable.grid>.wide.column{
    padding:.5em 0 !important;
  }

}
pre {
  margin: 0em;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
